import React, { useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import moment from "moment";
import { useFormikContext } from "formik";
import { Typography, useTheme } from "@material-ui/core";
import styled from "styled-components";
import { FormValues } from "../containers/InvoiceForm";

const DateRangeContainer = styled.div`
  /* margin-top: 0.5rem; */
`;

const Container = styled.div`
  width: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
`;

const DateRange = () => {
  const theme = useTheme();
  const [focused, setFocused] = useState<FocusedInputShape | null>(null);
  const form = useFormikContext<FormValues>();

  return (
    <Container>
      <Typography color="textSecondary">
        Period
      </Typography>
      <DateRangeContainer>
        <DateRangePicker
          noBorder
          displayFormat="DD.MM.YYYY"
          startDate={
            form.values.start_date ? moment(form.values.start_date) : null
          }
          startDateId="start_date"
          endDate={
            form.values.end_date ? moment(form.values.end_date) : null
          }
          endDateId="end_date"
          onDatesChange={({ startDate, endDate }) => {
            form.setFieldValue("start_date", startDate?.toDate());
            form.setFieldValue("end_date", endDate?.toDate());
          }}
          focusedInput={focused}
          onFocusChange={focusedInput => setFocused(focusedInput)}
          isOutsideRange={() => false}
          withFullScreenPortal={window.innerWidth < theme.breakpoints.values.md}
          orientation={
            window.innerWidth < theme.breakpoints.values.md
              ? "vertical"
              : "horizontal"
          }
          daySize={48}
          hideKeyboardShortcutsPanel={true}
          firstDayOfWeek={1}
        />
      </DateRangeContainer>
    </Container>
  );
};

export default DateRange;
