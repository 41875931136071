import { createMuiTheme } from "@material-ui/core";

export const themeVariables = {
  primaryColor: "#29b9da",
  secondaryColor: "#004860"
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeVariables.primaryColor,
      contrastText: "white"
    },
    secondary: {
      main: themeVariables.secondaryColor,
      contrastText: "white"
    }
  },
  typography: {
    h1: {
      fontFamily: "Chambers Sans",
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h2: {
      fontFamily: "Chambers Sans",
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: "Chambers Sans",
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h4: {
      fontFamily: "Chambers Sans",
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h5: {
      fontFamily: "Chambers Sans",
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    h6: {
      fontFamily: "Chambers Sans",
      fontWeight: 'bold',
      textTransform: 'uppercase'
    }
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: "capitalize"
      },
      textPrimary: {
        textTransform: "capitalize"
      },
      textSecondary: {
        textTransform: "capitalize"
      }
    }
  }
});

export default theme;
