import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FullscreenLoading from "./components/FullscreenLoading";
import Dashboard from "./containers/Dashboard";
import InvoiceDetails from "./containers/InvoiceDetails";
import InvoiceForm from "./containers/InvoiceForm";
import useAuth from "./utils/useAuth";

const App = () => {
  const {isLoggedIn} = useAuth()

  if (!isLoggedIn) {
    return <FullscreenLoading />;
  }

  return (
    <>
      {/* <Header /> */}
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/create" component={InvoiceForm} />
          <Route path="/:id" component={InvoiceDetails} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
