import React from "react";
import { useRouteMatch } from "react-router-dom";
import ErrorIcon from "@material-ui/icons/Warning";
import MainLayout from "../../layouts/MainLayout";
import { useGetOne } from "../../utils/customHooks";
import { Typography } from "@material-ui/core";
import { IInvoice } from "../../types";
import InvoiceDetailItem from "../../components/InvoiceDetailItem";
import moment from "moment";
import currencyFormatter from "../../utils/currencyFormatter";
import PdfDisplay from "../../components/PdfDisplay";
import FullscreenLoading from "../../components/FullscreenLoading";
import styled from "styled-components";

const DATE_FORMAT = "DD MMM YYYY";

const StateLabel = styled(Typography)`
&& {
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}`

const InvoiceDetails = () => {
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;
  
  const { data, loading, error } = useGetOne("invoices", id);
  const invoice = data as unknown as IInvoice;
  const getDateValue = () => {
    return `${moment(invoice.start_date).format(DATE_FORMAT)} - ${moment(
      invoice.end_date
    ).format(DATE_FORMAT)}`;
  };

const Title = invoice &&  <span>{`Invoice #${id}`} <StateLabel variant="h6" color="primary" display="inline">{invoice.state_label}</StateLabel> </span>

  return (
    <MainLayout title={Title}>
      {loading && <FullscreenLoading data-testid="loading" />}
      {error && (
        <Typography color="error" data-testid="error">
          <ErrorIcon color="error" />
          {error.message}
        </Typography>
      )}
      {invoice && (
        <div>
          <InvoiceDetailItem label="Project" value={invoice.project_name} />
          <InvoiceDetailItem label="State" value={invoice.state_label} />
          <InvoiceDetailItem
            label="Net Amount"
            value={currencyFormatter.format(Number(invoice.amount_net))}
          />
          <InvoiceDetailItem
            label="Tax"
            value={invoice.tax || 0}
          />
          <InvoiceDetailItem
            label="Gross Amount"
            value={currencyFormatter.format(Number(invoice.amount_gross))}
          />
          <InvoiceDetailItem label="Period" value={getDateValue()} />
          <InvoiceDetailItem
            label="Details"
            value={invoice.line_items[0]!.message}
          />
      
          {data.pdf?.url && (
            <InvoiceDetailItem
              label="Invoice PDF"
              value={<PdfDisplay fileUrl={data.pdf.url} />}
            />
          )}
        </div>
      )}
    </MainLayout>
  );
};

export default InvoiceDetails;
