import axios from "axios";
import * as Sentry from '@sentry/browser';
import jwt from 'jwt-decode'
import { IEndpoint } from "../types";
import { getToken, removeToken } from "./storageService";
import userManager from "./userManager";
 
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

httpClient.interceptors.request.use(request => {
  const token = getToken()

  // Do not add token for login
  if (request.url?.includes("login")) {
    return request
  }

  request.headers["Authorization"] = `JWT ${token}`;

  return request;
});

httpClient.interceptors.response.use(response => {
  return response;
}, error => {
  const statusCode = error.response.status;
  if (statusCode === 401) {
    removeToken()
    window.location.reload()
    return Promise.reject()
  }

  if (process.env.REACT_APP_SENTRY_DSN) {
    const token = getToken()
    const decodedToken: any = jwt(token!)
    Sentry.setUser(decodedToken)
    Sentry.withScope(scope => {
      scope.setExtra("request", error.config)
      Sentry.captureException(error);
    })
  }

  if(error.response?.data) {
    return Promise.reject(error.response.data)
  }

})

const login: IEndpoint = {
  login: async () => {
    const user = await userManager.getUser()
    const response = await httpClient.get("/login/", { headers: { "Authorization": `Bearer ${user?.id_token}` } });
    return response.data
  }
};

const invoices: IEndpoint = {
  list: async () => {
    const response = await httpClient.get(
      "/payment/invoices/?ordering=-id&page_size=100"
    );
    return response.data;
  },
  one: async (id: string) => {
    const response = await httpClient.get(`/payment/invoices/${id}/`);
    return response.data;
  },
  pdf: async (id: string) => {
    const response = await httpClient.get(
      `/payment/invoices/${id}/download/pdf/`,
      { responseType: "blob" }
    );
    return response.data;
  },
  create: async (data: any) => {
    const response = await httpClient.post("/payment/invoices/", data);
    return response.data;
  }
};

const projects: IEndpoint = {
  list: async () => {
    const response = await httpClient.get("/projects/?ordering=-id&page_size=100");
    return response.data;
  }
};

const apiService = { invoices, projects, login };

export default apiService;
