import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from "@material-ui/core/styles";
import { pdfjs } from 'react-pdf';

import 'normalize.css';
import "./index.css";
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './assets/styles/reactDates.css'
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import theme from "./theme";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
}

const AppContainer = (
  <ThemeProvider theme={theme}>
    <ToastContainer />
    <App />
  </ThemeProvider>
);

ReactDOM.render(AppContainer, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
