import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const FullscreenLoading = styled(CircularProgress)`
position: fixed;
width: 10rem;
height: 10rem;
top: 50%;
left: 50%;
`

export default FullscreenLoading