import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Button } from "@material-ui/core";
import { AttachFile as FileIcon } from "@material-ui/icons";
import { FieldInputProps, useFormikContext } from "formik";
import styled from "styled-components";
import { themeVariables } from "../theme";

interface IProps extends FieldInputProps<any> {
  label: string;
}

const DropzoneContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Dropzone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropIndicator = styled(props => <div {...props} />)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${themeVariables.primaryColor};
  opacity: ${(props: any) => (props.dragging ? 1 : 0)};
  transition: 200ms;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileShowField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`;

const FileDropzone = ({ label, name, value }: IProps) => {
  const form = useFormikContext();

  const onDrop = useCallback(
    acceptedFiles => {
      form.setFieldValue(name, acceptedFiles[0]);
    },
    [name, form]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false
  });

  return (
    <DropzoneContainer {...getRootProps()}>
      <DropIndicator dragging={isDragActive}>
        <Typography>Drop files here</Typography>
      </DropIndicator>
      <Dropzone>
        <Typography color="textSecondary">{label}</Typography>
        <Button color="primary">Add</Button>
        <input {...getInputProps()} />
      </Dropzone>
      {value && (
        <FileShowField>
          <FileIcon color="secondary" />
          <Typography color="secondary">{value.name}</Typography>
        </FileShowField>
      )}
    </DropzoneContainer>
  );
};

export default FileDropzone;
