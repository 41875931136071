import React from "react";
import { MenuItem } from "@material-ui/core";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { FormValues } from "../containers/InvoiceForm";
import LargeTextField from "./LargeTextField";

const Container = styled.div`
  width: 100%;
`;

const TaxSelector = () => {
  const form = useFormikContext<FormValues>();

  const onTaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("tax", e.target.value);
  };

  return (
    <Container>
      <LargeTextField
        select
        value={form.values.tax}
        onChange={onTaxChange}
        fullWidth
        InputProps={{ disableUnderline: true }}
        label="Tax (VAT)"
      >
        <MenuItem value={0}>%0</MenuItem>
        <MenuItem value={7}>%7</MenuItem>
        <MenuItem value={16}>%16</MenuItem>
        <MenuItem value={19}>%19</MenuItem>
      </LargeTextField>
    </Container>
  );
};

export default TaxSelector;
