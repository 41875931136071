import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

const userManager = new UserManager({
  userStore: new WebStorageStateStore(),
  authority:
    'https://login.microsoftonline.com/033861a9-58a1-4494-b743-68dbfaaf6253/oauth2/v2.0',
  metadataUrl:
    'https://login.microsoftonline.com/033861a9-58a1-4494-b743-68dbfaaf6253/v2.0/.well-known/openid-configuration',
  client_id: '21d176db-0160-481c-a40c-47e583ad4025',
  redirect_uri: window.location.origin + '/login',
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
})

export default userManager
