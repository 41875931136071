import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import StyledDivider from "./StyledDivider";

interface IProps {
  label: string;
  value: string | React.ReactNode;
}

const DetailItem = styled.div`
margin-top: 0.75rem;
`

const InvoiceDetailItem = ({ label, value }: IProps) => {
  return (
    <DetailItem>
      <Typography gutterBottom color="textSecondary" variant="caption">
        {label}
      </Typography>
      <Typography>{value}</Typography>
      <StyledDivider />
    </DetailItem>
  );
};

export default InvoiceDetailItem;
