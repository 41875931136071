import styled from "styled-components";
import { TextField } from "@material-ui/core";

const LargeTextField = styled(TextField)`
  && {
    @media screen and (min-width: 960px) {
      .MuiInputLabel-shrink {
        transform: translate(0, -0.25rem);
      }
    }
  }
`;

export default LargeTextField