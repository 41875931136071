import React, { useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { FieldProps } from "formik";
import styled from "styled-components";
import StyledDivider from "./StyledDivider";
import LargeTextField from "./LargeTextField";

interface IProps extends FieldProps {
  label: string;
  inputComponent: React.ElementType;
  inputAdditionalProps: any;
  required: boolean;
  multiline?: boolean
}

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
`;

const FormField = ({
  inputComponent = LargeTextField,
  required = true,
  ...props
}: IProps) => {
  const {
    field: { value, name },
    label,
    multiline,
    form: { errors, touched }
  } = props;
  const [editMode, setEditMode] = useState(false);
  const Component = inputComponent;  

  // If there is no value added and editmode is not active
  if (!value && !editMode && !required) {
    return (
      <div>
        <FieldContainer>
          <Typography>{label}</Typography>
          <Button
            color="primary"
            onClick={() => setEditMode(true)}
            data-testid="add-button"
          >
            Add
          </Button>
        </FieldContainer>
        {errors[name] && touched[name] && (
          <Typography variant="caption" color="error" data-testid="error">
            {errors[name]}
          </Typography>
        )}
        <StyledDivider />
      </div>
    );
  } else {
    return (
      <div>
        <FieldContainer>
          <Component
            label={label}
            fullWidth
            InputProps={{ disableUnderline: true, ...props.inputAdditionalProps }}
            data-testid="input"
            multiline={multiline}
            {...props.field}
          />
        </FieldContainer>
        {errors[name] && touched[name] && (
          <Typography variant="caption" color="error" data-testid="error">
            {errors[name]}
          </Typography>
        )}
        <StyledDivider />
      </div>
    );
  }
};

export default FormField;
