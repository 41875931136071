import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import apiService from "./apiService";
import { IEndpoint } from "../types";

type EndpointTargets = typeof apiService;

export const useGetList = (resource: keyof EndpointTargets) => {
  const [data, setData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | Error>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiService[resource].list!();
        setData(response.results);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e) {
          // TODO error handling
          setError(e);
          toast.error(e.message);
        } else {
          const error = { message: "Unknown Error." };
          setError(error);
          toast.error(error.message);
        }
      }
    };

    fetchData();
  }, [resource]);

  return { data, loading, error };
};

export const useGetOne = (resource: keyof EndpointTargets, id: string) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | Error>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiService[resource].one!(id);
        setData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e) {
          // TODO error handling
          setError(e);
          toast.error(e.message);
        } else {
          const error = { message: "Unknown Error." };
          setError(error);
          toast.error(error.message);
        }
      }
    };

    fetchData();
  }, [resource, id]);

  return { data, loading, error };
};

export const useApi = (
  resource: keyof EndpointTargets,
  method: keyof IEndpoint,
  id?: string,
) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | Error>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        setLoading(true);
        switch (method) {
          case "list":
            response = await apiService[resource].list!();
            break;
          default:
            response = await apiService[resource][method]!(id!);
            break;
        }
        setData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e) {
          // TODO error handling
          setError(e);
          toast.error(e.message);
        } else {
          const error = { message: "Unknown Error." };
          setError(error);
          toast.error(error.message);
        }
      }
    };

    fetchData();
  }, [resource, id, method]);

  return { data, loading, error };
};