import React from "react";
import { List, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Warning";
import AddIcon from "@material-ui/icons/Add";
import { IInvoice } from "../../types";
import InvoiceListItem from "../../components/InvoiceListItem";
import MainLayout from "../../layouts/MainLayout";
import { useGetList } from "../../utils/customHooks";
import { useHistory } from "react-router-dom";
import FullscreenLoading from "../../components/FullscreenLoading";
import styled from "styled-components";

const EmptyInvoicesContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Dashboard = () => {
  const { data, loading, error } = useGetList("invoices");
  const history = useHistory();

  const layoutAction = {
    action: () => history.push("/create"),
    label: "Create Invoice",
    icon: <AddIcon />
  };

  const rows = data as unknown as IInvoice[];
  return (
    <MainLayout parent title="Invoices" layoutAction={layoutAction}>
      {loading && <FullscreenLoading data-testid="loading" />}
      {error && (
        <Typography color="error" data-testid="error">
          <ErrorIcon color="error" />
          {error.message}
        </Typography>
      )}
      <List data-testid="list">
        {rows?.map(invoice => (
          <InvoiceListItem invoice={invoice} key={invoice.id} />
        ))}
      </List>
      {rows?.length === 0 && (
        <EmptyInvoicesContainer>
          <Typography variant="h6" color="primary">
            You don't have any invoices yet.
          </Typography>
          <Typography variant="body2">You can create one through the create invoice button</Typography>
        </EmptyInvoicesContainer>
      )}
    </MainLayout>
  );
};

export default Dashboard;
