import React from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { LinearProgress, MenuItem } from "@material-ui/core";
import { useGetList } from "../utils/customHooks";
import { FormValues } from "../containers/InvoiceForm";
import { IProject } from "../types";
import LargeTextField from "./LargeTextField";

const Container = styled.div`
  width: 100%;
`

const CostCenterSelect = () => {
  const { data, error, loading } = useGetList("projects");
  const projects = data as unknown as IProject[];
  const form = useFormikContext<FormValues>();

  const onCostCenterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const project = projects.find(p => p.id === e.target.value);
    form.setFieldValue("project_id", e.target.value);
    form.setFieldValue("cost_center", project?.cost_center);
    form.setFieldValue("client_contract", project?.client_contract);
  };

  return (
    <Container>
      {loading && <LinearProgress color="primary" />}
      {error && <div>Error</div>}
      {projects ? (
        <LargeTextField select value={form.values.project_id} onChange={onCostCenterChange} fullWidth InputProps={{disableUnderline: true}} label= "Project">
          {projects.map(project => (
            <MenuItem key={project.id} value={project.id}>
              {project.label}
            </MenuItem>
          ))}
        </LargeTextField>
      ) : null}
    </Container>
  );
};

export default CostCenterSelect;
