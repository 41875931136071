import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  open: boolean,
  handleClose: () => void;
  title: string;
  content: string;
}

const AlertDialog = ({ open, handleClose, title, content }: Props) => {
  return (
    <Dialog
      open={open}
      disableBackdropClick
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Okay
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog