import React from "react";
import { Typography, Grid, IconButton, Button, Fab } from "@material-ui/core";
import { ChevronLeft as BackIcon } from "@material-ui/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CenteredContainer = styled(Grid)`
  justify-content: center;
  padding: 1rem;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled(Grid)`
  && {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
`;

const BackButton = styled(({ className, ...props }) => (
  <IconButton {...props} className={className} />
))`
  && {
    padding: 0;
    padding-right: 0.5rem;
  }
`;

const LayoutActionButton = styled(({ className, ...props }) => (
  <Button {...props} className={className} />
))`
  && {
    display: none;
    margin-left: auto;

    @media (min-width: 960px) {
      display: inherit;
    }
  }
`;

const LayoutActionFab = styled(props => <Fab {...props} />)`
  position: fixed !important;
  right: 1rem;
  bottom: 1rem;
  z-index: 99;

  @media (min-width: 960px) {
    display: none !important;
  }
`;

interface IProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  parent?: boolean;
  layoutAction?: {
    action: () => void,
    label: string | React.ReactNode,
    icon: JSX.Element
  }
}

const MainLayout = ({
  children,
  parent = false,
  title,
  layoutAction
}: IProps) => {
  return (
    <CenteredContainer container>
      <Grid item xs={12} md={8}>
        <TitleSection>
          {!parent && (
            <BackButton component={Link} to="/" data-testid="back-button">
              <BackIcon fontSize="large" />
            </BackButton>
          )}
          <Typography variant="h5" data-testid="title">
            {title}
          </Typography>
          {layoutAction && (
            <>
              <LayoutActionButton
                variant="contained"
                color="secondary"
                onClick={layoutAction.action}
              >
                {layoutAction.label}
              </LayoutActionButton>
              <LayoutActionFab component={Link} to="/create" color="secondary">
                {layoutAction.icon}
              </LayoutActionFab>
            </>
          )}
        </TitleSection>
        <Content item xs={12}>
          {children}
        </Content>
      </Grid>
    </CenteredContainer>
  );
};

export default MainLayout;
