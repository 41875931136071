import { useEffect, useState } from "react"
import apiService from "./apiService"
import { setToken } from "./storageService"
import userManager from "./userManager"

const useAuth = () => {
    const [isLoggedIn, setLoggedIn] = useState(false)

    useEffect(() => {
        const isCallback = window.location.pathname === '/login'
        if (isCallback) {
            userManager.signinRedirectCallback().then(async e => {
                window.history.replaceState({}, '', (e.state as any).path)
                const { token } = await apiService.login.login!();
                setToken(token);
                setLoggedIn(true)
            })
        } else {
            userManager.getUser().then(async user => {
                if (user) {
                    if (user.expired) {
                        localStorage.clear();
                        userManager
                        .signinRedirect({ state: { path: window.location.pathname } })
                        .then(a => {
                            setLoggedIn(false)
                        })
                    } else {
                        const { token } = await apiService.login.login!();
                        setToken(token);
                        setLoggedIn(true)
                    }
                } else {
                    userManager
                        .signinRedirect({ state: { path: window.location.pathname } })
                        .then(a => {
                            setLoggedIn(false)
                        })
                }
            })
        }
    }, [])

    return { isLoggedIn }
}

export default useAuth