import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Grid, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import styled from "styled-components";
import * as Yup from "yup";
import MainLayout from "../../layouts/MainLayout";
import FormField from "../../components/FormField";
import FileDropzone from "../../components/FileDropzone";
import DateRange from "../../components/DateRange";
import apiService from "../../utils/apiService";
import moment from "moment";
import { ScaleLoader } from "react-spinners";
import CostCenterSelect from "../../components/CostCenterSelect";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AlertDialog from "../../components/AlertDialog";
import TaxSelector from "../../components/TaxSelector";

const DATE_FORMAT = "YYYY-MM-DD";

export type FormValues = {
  project_id: null | number;
  cost_center: string;
  client_contract: null | number;
  amount_net: string;
  tax: number;
  message: string;
  start_date: null | Date;
  end_date: null | Date;
  pdf: null | File;
};

const initialValues: FormValues = {
  project_id: null,
  cost_center: "",
  client_contract: null,
  amount_net: "",
  tax: 0,
  message: "",
  start_date: null,
  end_date: null,
  pdf: null,
};

const validationSchema = Yup.object().shape({
  project_id: Yup.number().required("Project is required."),
  amount_net: Yup.number().required("Please enter an amount"),
  tax: Yup.number().max(100).min(0),
  message: Yup.string().required("Please enter a message"),
  start_date: Yup.date().nullable(true).required("Range is required"),
  end_date: Yup.date().nullable(true).required("Range is required"),
  pdf: Yup.mixed().required("A file is required"),
});

const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const FullWidthButton = styled(Button)`
  && {
    margin-top: 2.5rem;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
`;

const HelperSidebar = styled(Grid)`
  && {
    display: none;

    @media screen and (min-width: 960px) {
      display: initial;
    }
  }
`;

const InfoIcon = styled(Info)`
  && {
    top: 0.25rem;
    position: relative;
  }
`;

const InvoiceForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  const onSubmitForm = async (values: FormValues) => {
    setSubmitting(true);
    const pdf = await convertFileToBase64(values.pdf!);
    const start_date = moment(values.start_date!).format(DATE_FORMAT);
    const end_date = moment(values.end_date!).format(DATE_FORMAT);
    try {
      await apiService.invoices.create!({
        cost_center: values.cost_center,
        client_contract: values.client_contract,
        line_items: [
          {
            amount_net: String(values.amount_net),
            message: values.message,
          },
        ],
        pdf,
        start_date,
        end_date,
        tax: values.tax / 100,
      });
      setSubmitting(false);
      setOpenDialog(true);
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      setSubmitting(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    history.push("/");
  };

  function onKeyDown(keyEvent: React.KeyboardEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      const target = keyEvent.target as any;
      if (target.tagName.toLowerCase() !== "textarea") {
        keyEvent.preventDefault();
      }
    }
  }

  return (
    <MainLayout title="Add Invoice">
      <Grid container spacing={8}>
        <Grid item xs={12} md={8}>
          <AlertDialog
            open={openDialog}
            handleClose={handleDialogClose}
            title="Almost Done"
            content="Your invoice is created. Please ask your project owner to review and approve your submitted invoice."
          />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmitForm}
            validationSchema={validationSchema}
          >
            <Form onKeyDown={onKeyDown}>
              <Field
                component={FormField}
                name="project_id"
                label="Project"
                inputComponent={CostCenterSelect}
              />
              <Field
                component={FormField}
                name="amount_net"
                label="Amount Net(€)"
                inputAdditionalProps={{
                  type: "number",
                  inputProps: { min: 0, step: "0.01" },
                }}
              />
              <Field
                component={FormField}
                name="tax"
                label="Tax (VAT)"
                inputComponent={TaxSelector}
              />
              <Field
                component={FormField}
                name="start_date"
                label="Period"
                inputComponent={DateRange}
              />
              <Field
                component={FormField}
                name="message"
                label="Details"
                multiline
              />
              <Field
                component={FormField}
                name="pdf"
                label="Invoice PDF"
                inputComponent={FileDropzone}
              />
              <FullWidthButton
                type="submit"
                variant="contained"
                color="secondary"
              >
                {submitting ? <ScaleLoader color="white" /> : "Submit"}
              </FullWidthButton>
            </Form>
          </Formik>
        </Grid>
        <HelperSidebar item md={4}>
          <Typography variant="h5" color="primary" gutterBottom>
            <InfoIcon color="primary" /> Creating new invoices
          </Typography>
          <Typography gutterBottom>
            Please select the project which invoice is related. Enter the amount
            matching the invoice and select the date range covering this
            invoice.
          </Typography>
          <Typography variant="h5" color="primary" gutterBottom>
            <InfoIcon color="primary" /> Approval
          </Typography>
          <Typography gutterBottom>
            After the invoice is created, it will be reviewed by the Project
            Owner and Finance team and you will be notified by email when there
            is progress. For any questions please contact us:{" "}
            <Typography
              component="a"
              href="mailto:pt@motius.de"
              color="primary"
            >
              pt@motius.de.
            </Typography>
          </Typography>
        </HelperSidebar>
      </Grid>
    </MainLayout>
  );
};

export default InvoiceForm;
