import React from "react";
import styled from "styled-components";
import { Typography, Paper } from "@material-ui/core";
import { IInvoice } from "../types";
import moment from "moment";
import currencyFormatter from "../utils/currencyFormatter";
import { useHistory } from "react-router-dom";

const DATE_FORMAT = "DD MMM";

interface IProps {
  invoice: IInvoice;
}

const InvoiceContainer = styled((props: any) => <Paper {...props} />)`
&& {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  padding: 1rem;
  cursor: pointer;
  background-color: ${(props: IProps) => props.invoice.state_label === "Rejected" ? "#00000015" : "white"};

  &:hover {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
`;

const StateLabel = styled(Typography)`
  && {
    margin-left: 0.5rem;
  }
`;


const InvoiceListItem = ({ invoice }: IProps) => {
  const history = useHistory();

  const onInvoiceClick = () => {
    history.push(`/${invoice.id}`);
  };

  return (
    <InvoiceContainer invoice={invoice} elevation={1} onClick={onInvoiceClick} data-testid="container">
      <div>
        <Typography>{invoice.project_name || invoice.id}</Typography>
        <Typography variant="caption" color="textSecondary">
          <span>{moment(invoice.created).format(DATE_FORMAT)}</span>
          <StateLabel variant="caption" color={invoice.state_label === "Rejected" ? "error" : "primary"}>
            {invoice.state_label}
          </StateLabel>
        </Typography>
      </div>
      <div>
        <Typography>
          {currencyFormatter.format(Number(invoice.amount_net))}
        </Typography>
      </div>
    </InvoiceContainer>
  );
};

export default InvoiceListItem;
