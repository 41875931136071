import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import styled from "styled-components";
import { themeVariables } from "../theme";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";

interface IProps {
  fileUrl: string;
}

const Container = styled.div`
  position: relative;
  height: 200px;
  overflow: hidden;

  @media screen and (min-width: 960px) {
    height: auto;
  }
`;

const DownloadOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  z-index: 2;
  color: white;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div {
    opacity: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.6);

    div {
      opacity: 1;
    }
  }
`;

const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  /* background-color: ${themeVariables.primaryColor}; */
  padding: 0.5rem 1rem;
  transition: 200ms;
  border-radius: 5px;

  svg {
    margin-right: 0.5rem;
  }

  h6 {
    font-family: "Roboto", sans-serif;
  }
`;

const PdfPage = styled(Page)`
  width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export const openSaveFileDialog = (data: any) => {
  if ((navigator as any).msSaveBlob) {
    (navigator as any).msSaveBlob(data, "invoice.pdf");
    return;
  }

  let lnk = document.createElement("a"),
    url = window.URL,
    objectURL;

  lnk.type = "application/octet-stream";

  lnk.download = "invoice.pdf";
  lnk.href = objectURL = url.createObjectURL(data);
  lnk.dispatchEvent(new MouseEvent("click"));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};

const PdfDisplay = ({ fileUrl }: IProps) => {
  const [data, setData] = useState<string | ArrayBuffer | null>(null);
  const [blobData, setBlobData] = useState<Blob>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.blob();
      })
      .then((blob) => {
        setBlobData(blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          setData(reader.result);
        };
      })
      .catch((error) => {
        if ([400, 403].includes(error.status)) {
          error.json().then(() => {
            toast.error("An error occurred while loading invoice pdf");
          });
        } else if (error.status === 500) {
          toast.error("Server error, please contact support");
        } else {
          toast.error("An error occurred");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fileUrl]);

  const onDownloadClick = () => {
    openSaveFileDialog(blobData);
  };

  return (
    <Container>
      <DownloadOverlay onClick={onDownloadClick}>
        <DownloadButton>
          <Typography variant="h6">Click To Download</Typography>
        </DownloadButton>
      </DownloadOverlay>
      <Document file={data}>
        {!loading && <PdfPage pageNumber={1} />}
        {loading && <p>Loading ...</p>}
      </Document>
    </Container>
  );
};

export default PdfDisplay;
